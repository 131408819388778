@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 262 100% 7%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 262 100% 7%;

		--primary: 227 99% 51%;
		--primary-foreground: 278 100% 96%;

		--secondary: 181 88% 77%;
		--secondary-foreground: 262 100% 7%;

		--tertiary: 36 100% 67%;
		--tertiary-foreground: 262 100% 7%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--ring: 222.2 84% 4.9%;

		--radius: 0.5rem;
	}

	.dark {
		--background: 262 100% 7%;
		--foreground: 278 100% 96%;

		--card: 262 100% 7%;
		--card-foreground: 210 40% 98%;

		--popover: 262 100% 7%;
		--popover-foreground: 278 100% 96%;

		--primary: 227 99% 51%;
		--primary-foreground: 278 100% 96%;

		--secondary: 181 88% 77%;
		--secondary-foreground: 262 100% 7%;

		--tertiary: 36 100% 67%;
		--tertiary-foreground: 262 100% 7%;

		--muted: 217.2 32.6% 17.5%;
		--muted-foreground: 215 20.2% 65.1%;

		--accent: 217.2 32.6% 17.5%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 210 40% 98%;

		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--ring: 212.7 26.8% 83.9%;
	}
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
	font-size: 16px;
}
